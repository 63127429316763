<template>
  <el-dropdown trigger="click" :hide-on-click="false">
    <el-select
      :value="value"
      size="small"
      :disabled="disabled"
      popper-class="multi-select-input"
      :placeholder="placeholder ?? $t('select')"
      :style="{ width }"
    />
    <MultiSelectDropdown v-bind="$props" @on-select="onSelect" @load-more="$emit('on-load-more')" />
  </el-dropdown>
</template>
<script>
import MultiSelectDropdown from '../../tasks/components/MultiSelectDropdown.vue';

export default {
  name: 'MultiSelectInput',
  components: { MultiSelectDropdown },
  props: {
    value: { type: String, default: null },
    withSubmit: { type: Boolean, default: false },
    title: { type: String, default: '' },
    options: { type: Array, required: true },
    selected: { type: Array, required: false, default: () => [] },
    searchable: { type: Boolean, default: false },
    placeholder: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    width: { type: String, default: '100%' },
    selectAll: { type: Boolean, default: false },
  },
  emits: ['on-select', 'on-load-more'],
  setup(_, { emit }) {
    const onSelect = (updatedSelected) => {
      emit('on-select', updatedSelected);
    };

    return {
      onSelect,
    };
  },
};
</script>
<style lang="scss" scoped></style>
<style lang="scss">
.multi-select-input {
  display: none;
}
</style>
