<template>
  <Modal
    :visible="visible"
    :title="catalog?.name"
    :submit-text="$t('commons.save')"
    :submit-disabled="submitDisabled"
    :submit-loading="updateLoading"
    :custom-class="activeTab === TABS.PRODUCTS ? 'fixed-height' : ''"
    :top="'17vh'"
    @on-close="onClose"
    @on-submit="onSubmit"
  >
    <template #subtitle>
      <p>{{ catalog?.business.name }}</p>
    </template>
    <Tabs :tabs="tabs" :active-tab.sync="activeTab" />
    <CatalogDetails
      v-show="activeTab === TABS.DETAILS"
      :supplier="catalog?.business"
      :existing-catalog="catalog"
      :show-title="false"
      :is-supplier-view="isSupplierView"
      @on-catalog-change="onCatalogChange"
    />
    <CatalogProducts
      v-show="activeTab === TABS.PRODUCTS"
      :supplier="catalog?.business"
      :products-in-catalog="catalog?.productIds"
      :show-title="false"
      @on-selected-products="onCatalogProductsChange"
    />
  </Modal>
</template>
<script>
import { ref, computed, getCurrentInstance } from 'vue';

import { Tabs } from '@/modules/core';

import Modal from './Modal.vue';
import CatalogDetails from './CatalogDetails.vue';
import CatalogProducts from './CatalogProducts.vue';
import { useCatalogUpdate } from '../compositions/useCatalogUpdate';

const TABS = {
  DETAILS: 0,
  PRODUCTS: 1,
};
export default {
  name: 'EditCatalogModal',
  components: { Tabs, Modal, CatalogDetails, CatalogProducts },
  props: {
    catalog: { type: Object, default: null },
    visible: { type: Boolean, default: false },
    isSupplierView: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['on-close'],
  setup(props, { emit }) {
    const root = getCurrentInstance().proxy;
    const activeTab = ref(TABS.DETAILS);
    const catalogToUpdateTo = ref();

    const tabs = computed(() => [
      {
        text: root.$t('catalog.editModal.tabs.details'),
      },
      {
        text: root.$t('catalog.editModal.tabs.products', { count: props.catalog?.productIds?.length }),
      },
    ]);

    const submitDisabled = computed(() => {
      let hasDifferenceInProducts = false;
      if (catalogToUpdateTo.value?.productIds) {
        for (const productId of catalogToUpdateTo.value?.productIds) {
          if (!props.catalog?.productIds?.includes(productId)) {
            hasDifferenceInProducts = true;
          }
        }

        if (catalogToUpdateTo.value.productIds.length !== props.catalog.productIds.length) {
          hasDifferenceInProducts = true;
        }
      }

      let hasDifferenceInCatalog = false;
      if (catalogToUpdateTo.value?.name && catalogToUpdateTo.value?.name !== props.catalog?.name) {
        hasDifferenceInCatalog = true;
      }

      if (catalogToUpdateTo.value?.exposedToBusinesses) {
        for (const businessId of catalogToUpdateTo.value?.exposedToBusinesses) {
          if (!props.catalog?.exposedToBusinesses?.includes(businessId)) {
            hasDifferenceInCatalog = true;
          }
        }

        if (catalogToUpdateTo.value.exposedToBusinesses.length !== props.catalog.exposedToBusinesses.length) {
          hasDifferenceInCatalog = true;
        }
      }

      return !hasDifferenceInProducts && !hasDifferenceInCatalog;
    });

    const { updateCatalog, onDone, loading: updateLoading } = useCatalogUpdate();

    onDone(() => {
      root.$message.success(root.$t('tasks.defineProductTask.catalogForm.updatedSuccessfully'));
      emit('on-updated-catalog');
    });

    const onClose = () => {
      emit('on-close');
    };

    const onSubmit = () => {
      updateCatalog({
        id: props.catalog?.id,
        params: catalogToUpdateTo.value,
      });
    };

    const onCatalogChange = (updatedCatalog) => {
      catalogToUpdateTo.value = updatedCatalog;
    };

    const onCatalogProductsChange = (newSelectedProducts) => {
      catalogToUpdateTo.value = {
        ...catalogToUpdateTo.value,
        productIds: newSelectedProducts,
      };
    };

    return {
      TABS,
      tabs,
      activeTab,
      updateLoading,
      submitDisabled,
      onClose,
      onSubmit,
      onCatalogChange,
      onCatalogProductsChange,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

::v-deep {
  .fixed-height {
    height: 700px;
  }
}
</style>
