import i18n from '@/imports/startup/client/i18n';

export const CATALOG_TABLE_HEADERS = {
  NAME: 'name',
  SUPPLIER: 'supplier',
  PRODUCT_COUNT: 'productCount',
  LINKED_BUSINESSES: 'linkedBusinesses',
  ACCESS: 'access',
};

export const CATALOG_PRODUCTS_TABLE_HEADER = {
  NAME: 'name',
  SKU: 'sku',
  CATEGORY: 'category',
};

const getTranslationForCatalog = (key) => i18n.t(`catalog.table.headers.${key}`);

export const getCatalogColumns = (isSupplierView = false) =>
  isSupplierView
    ? [
        {
          header: getTranslationForCatalog(CATALOG_TABLE_HEADERS.NAME),
          key: CATALOG_TABLE_HEADERS.NAME,
          width: '15rem',
        },
        {
          header: getTranslationForCatalog(CATALOG_TABLE_HEADERS.PRODUCT_COUNT),
          key: CATALOG_TABLE_HEADERS.PRODUCT_COUNT,
          width: '7.25rem',
        },
        {
          header: getTranslationForCatalog(CATALOG_TABLE_HEADERS.LINKED_BUSINESSES),
          key: CATALOG_TABLE_HEADERS.LINKED_BUSINESSES,
          width: '8rem',
        },
        {
          header: getTranslationForCatalog(CATALOG_TABLE_HEADERS.ACCESS),
          key: CATALOG_TABLE_HEADERS.ACCESS,
          width: '8rem',
        },
        {
          header: '',
          width: '60px',
          key: 'kebab',
          customClass: 'p-0',
        },
      ]
    : [
        {
          header: getTranslationForCatalog(CATALOG_TABLE_HEADERS.NAME),
          key: CATALOG_TABLE_HEADERS.NAME,
          width: '15rem',
        },
        {
          header: getTranslationForCatalog(CATALOG_TABLE_HEADERS.SUPPLIER),
          key: CATALOG_TABLE_HEADERS.SUPPLIER,
          width: '15rem',
        },
        {
          header: getTranslationForCatalog(CATALOG_TABLE_HEADERS.PRODUCT_COUNT),
          key: CATALOG_TABLE_HEADERS.PRODUCT_COUNT,
          width: '7.25rem',
        },
        {
          header: getTranslationForCatalog(CATALOG_TABLE_HEADERS.LINKED_BUSINESSES),
          key: CATALOG_TABLE_HEADERS.LINKED_BUSINESSES,
          width: '8rem',
        },
        {
          header: getTranslationForCatalog(CATALOG_TABLE_HEADERS.ACCESS),
          key: CATALOG_TABLE_HEADERS.ACCESS,
          width: '8rem',
        },
        {
          header: '',
          width: '60px',
          key: 'kebab',
          customClass: 'p-0',
        },
      ];

const getTranslationForCatalogProducts = (key) => i18n.t(`catalog.products.table.headers.${key}`);

export const getCatalogProductsColumns = () => [
  {
    header: getTranslationForCatalogProducts(CATALOG_PRODUCTS_TABLE_HEADER.NAME),
    key: CATALOG_PRODUCTS_TABLE_HEADER.NAME,
    width: '15rem',
  },
  {
    header: getTranslationForCatalogProducts(CATALOG_PRODUCTS_TABLE_HEADER.SKU),
    key: CATALOG_PRODUCTS_TABLE_HEADER.SKU,
    width: '7.25rem',
  },
  {
    header: getTranslationForCatalogProducts(CATALOG_PRODUCTS_TABLE_HEADER.CATEGORY),
    key: CATALOG_PRODUCTS_TABLE_HEADER.CATEGORY,
    width: '13.5rem',
  },
  {
    header: '',
    width: '60px',
    key: 'kebab',
    customClass: 'p-0',
  },
];
