import { gql } from '@apollo/client/core';
import { getCurrentInstance, ref, watch } from 'vue';
import { useQuery } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';

export function useBusinessesForCatalog(variables, withDebounce = false) {
  const root = getCurrentInstance().proxy;
  const { error } = useNotification();
  const accumulatedBusinesses = ref([]);
  const totalCount = ref(0);

  const { result, loading, onError, refetch, onResult } = useQuery(BUSINESSES, variables, () => ({
    debounce: withDebounce ? 500 : 0,
  }));

  watch(result, (newValue) => {
    if (newValue?.businesses?.nodes) {
      accumulatedBusinesses.value = [...accumulatedBusinesses.value, ...newValue.businesses.nodes];
    }
    totalCount.value = newValue?.businesses?.totalCount || 0;
  });

  onError((err) => {
    console.error('useBusinesses', err);
    error(root.$t('contactsSet.errors.business.fetch'));
  });

  const clearBusinesses = () => {
    accumulatedBusinesses.value = [];
  };

  return {
    businesses: accumulatedBusinesses,
    totalCount,
    clearBusinesses,
    loading,
    refetch,
    onResult,
  };
}

const BUSINESSES = gql`
  query businesses($capabilities: BusinessCapabilitiesInput, $search: String, $first: Int, $after: Int) {
    businesses(capabilities: $capabilities, search: $search, first: $first, after: $after) {
      nodes {
        id
        name
        legalName
        type
      }
      totalCount
    }
  }
`;
