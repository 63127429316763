import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core/compositions/notification';

export const useCreateProductInCatalog = () => {
  const { error } = useNotification();

  const { mutate, loading, onError, onDone } = useMutation(CREATE_PRODUCT_IN_CATALOG);

  onError((err) => {
    console.error('useCreateProductInCatalog', err);
    error();
  });

  return {
    createProductInCatalog: mutate,
    loading,
    onDone,
    onError,
  };
};

const CREATE_PRODUCT_IN_CATALOG = gql`
  mutation catalogProductCreate($id: ID!, $params: CatalogProductCreateInput) {
    catalogProductCreate(id: $id, params: $params)
  }
`;
