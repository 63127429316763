import { gql } from '@apollo/client/core';
import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';

export function useCatalogs(variables, options) {
  const { error } = useNotification();

  const { result, loading, onError, refetch, onResult } = useQuery(CATALOG_QUERY, variables, options);
  const catalogConnection = computed(() => (result.value ? result.value.catalogs : { nodes: [], totalCount: 0 }));

  const totalCount = computed(() => catalogConnection.value.totalCount);

  const catalogs = computed(() => catalogConnection.value.nodes);

  onError((err) => {
    console.error('useCatalogs', err);
    error();
  });

  return {
    catalogs,
    refetch,
    loading,
    totalCount,
    onResult,
  };
}

export const CATALOG_QUERY = gql`
  query catalogs($businessId: ID, $first: Int, $after: Int) {
    catalogs(businessId: $businessId, first: $first, after: $after) {
      nodes {
        id
        name
        productIds
      }
      totalCount
    }
  }
`;
