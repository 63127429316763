import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core/compositions/notification';

export const useCatalogUpdate = () => {
  const { error } = useNotification();

  const { mutate, loading, onError, onDone } = useMutation(UPDATE_CATALOG);

  onError((err) => {
    console.error('useCatalogUpdate', err);
    error();
  });

  return {
    updateCatalog: mutate,
    loading,
    onDone,
  };
};

const UPDATE_CATALOG = gql`
  mutation catalogUpdate($id: ID!, $params: CatalogUpdateInput) {
    catalogUpdate(id: $id, params: $params) {
      id
    }
  }
`;
