import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core/compositions/notification';

export const useCatalogDelete = () => {
  const { error } = useNotification();

  const { mutate, loading, onError, onDone } = useMutation(DELETE_CATALOG);

  onError((err) => {
    console.error('useCatalogDelete', err);
    error();
  });

  return {
    deleteCatalog: mutate,
    loading,
    onDone,
  };
};

const DELETE_CATALOG = gql`
  mutation catalogDelete($id: ID!) {
    catalogDelete(id: $id)
  }
`;
