import { computed } from 'vue';
import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';

export function useProductsForCatalogCreation(businessId) {
  const { error } = useNotification();

  const { result, refetch, loading, onError } = useQuery(
    PRODUCTS_QUERY_NEW,
    () => ({ businessId: businessId.value }),
    () => ({
      enabled: !!businessId?.value,
    })
  );

  const products = computed(() => (result.value ? result.value.productsOldNew?.nodes ?? [] : []));

  onError((err) => {
    console.error('useProductsForCatalogCreation', err);
    error();
  });

  return {
    products,
    refetch,
    loading,
  };
}

const PRODUCTS_QUERY_NEW = gql`
  query products($businessId: ID) {
    productsOldNew(businessId: $businessId) {
      nodes {
        id
        name
        sku
        categoryId
      }
    }
  }
`;
