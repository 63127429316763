import { gql } from '@apollo/client/core';
import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';

export function useCatalogSuppliers(variables) {
  const { error } = useNotification();

  const { result, loading, onError, refetch, onResult } = useQuery(CATALOG_QUERY, variables);
  const catalogConnection = computed(() => (result.value ? result.value.catalogs : { nodes: [], totalCount: 0 }));

  const suppliers = computed(() => catalogConnection.value.nodes);

  onError((err) => {
    console.error('useCatalogSuppliers', err);
    error();
  });

  return {
    suppliers,
    refetch,
    loading,
    onResult,
  };
}

export const CATALOG_QUERY = gql`
  query catalogSuppliers($first: Int, $after: Int) {
    catalogs(first: $first, after: $after) {
      nodes {
        business {
          id
          name
        }
      }
      totalCount
    }
  }
`;
