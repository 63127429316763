<template>
  <el-dialog
    id="modal"
    :visible.sync="visible"
    append-to-body
    :custom-class="customClass"
    :show-close="false"
    :width="width"
    :close-on-click-modal="false"
    :before-close="() => $emit('on-close')"
    :top="top"
    :style="{ marginTop: top ? '' : '10vh' }"
  >
    <template #title>
      <div class="d-flex justify-content-between align-items-center">
        <div class="modal-title">{{ title }}</div>
        <Button type="text" class="p-0 text-typography-primary close-btn" @click="$emit('on-close')">
          <CloseIcon />
        </Button>
      </div>
      <slot name="subtitle"></slot>
    </template>
    <slot></slot>
    <template #footer>
      <div class="d-flex gap-1 justify-content-end">
        <Button type="secondary" @click="$emit('on-close')">{{ cancelText ?? $t('cancel') }}</Button>
        <Button type="primary" :disabled="submitDisabled" :loading="submitLoading" @click="$emit('on-submit')">{{
          submitText ?? $t('commons.save')
        }}</Button>
      </div>
    </template>
  </el-dialog>
</template>
<script>
import { CloseIcon } from '@/assets/icons';
import { Button } from '@/modules/core';

export default {
  name: 'Modal',
  components: { Button, CloseIcon },
  props: {
    visible: { type: Boolean, default: false },
    title: { type: String, required: true },
    submitText: { type: String, default: null },
    cancelText: { type: String, default: null },
    submitLoading: { type: Boolean, default: false },
    submitDisabled: { type: Boolean, default: false },
    customClass: { type: String, default: null },
    width: { type: String, default: null },
    top: { type: String, default: undefined },
  },
  emits: ['on-close', 'on-submit'],
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

::v-deep {
  .el-dialog__header {
    padding: 1rem;
    > div {
      padding: 0 !important;
    }
  }

  .el-dialog__body {
    height: 80%;
    padding: 1rem !important;
  }

  .el-dialog__footer {
    padding: 1rem;
  }
}

#modal {
  margin: 0 auto;

  ::v-deep {
    .el-dialog {
      border-radius: 0.5rem !important;
      width: 50rem;
    }
  }

  .modal-title {
    color: #131416;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.75rem;
    letter-spacing: -0.015rem;
  }

  .close-btn {
    height: fit-content;
    &:hover {
      background: $secondary;
    }
  }
}
</style>
