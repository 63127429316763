import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core/compositions/notification';

export const useCatalogCreate = () => {
  const { error } = useNotification();

  const { mutate, loading, onError, onDone } = useMutation(CREATE_CATALOG);

  onError((err) => {
    console.error('useCatalogCreate', err);
    error();
  });

  return {
    createCatalog: mutate,
    loading,
    onDone,
  };
};

const CREATE_CATALOG = gql`
  mutation catalogCreate($params: CatalogCreateInput) {
    catalogCreate(params: $params) {
      id
      name
    }
  }
`;
